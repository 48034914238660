<template>
  <SdPage>
    <div class="page-book-a-service">
      <template v-if="$viewport.gt.sm">
        <div class="row justify-content-center mt-3">
          <h2>Book a service</h2>
        </div>
        <div class="row text-center align-content-center justify-content-center">
          <div class="col-md-6">
            <p class="font-21">
              How can our agents help you?
            </p>
          </div>
        </div>
      </template>
      <div
        v-if="propertyRef"
        class="row no-gutters text-center align-content-center justify-content-around"
      >
        <ServicesDialog
          v-if="$viewport.lt.md"
          :property="propertyRef"
          @close="redirectToProperties"
        />
        <template v-else>
          <router-link
            class="close-services"
            :to="getUnitUrl()"
          >
            <i class="sdicon-close text-gray-dark font-28" />
          </router-link>
          <ServicesMenu
            :unit="propertyRef.units.length === 1 ? propertyRef.units[0] : undefined"
            :property="propertyRef"
          />
        </template>
      </div>
    </div>
  </SdPage>
</template>

<script>
import { ref } from '@vue/composition-api';
import { redirectToPropertiesPage } from '@/router';

import ServicesMenu from '../../../components/service/ServicesMenu';
import ServicesDialog from '../../../components/service/ServicesDialog';

export default {
  name: 'UnitBookAService',
  components: {
    ServicesDialog,
    ServicesMenu,
  },
  setup(props, context) {
    const propertyRef = ref(null);
    getProperty();
    return {
      propertyRef,
      redirectToProperties,
      getUnitUrl,
    };

    async function getProperty() {
        propertyRef.value = await context.root.$store.dispatch('Property/find', context.root.$route.params.id);
    }

    function redirectToProperties() {
      redirectToPropertiesPage(context);
    }

    function getUnitUrl() {
      return {
        name: propertyRef.value.units.length === 1 ? 'app.units.show' : 'app.units',
        params: {
          id: propertyRef.value.id,
          unit_id: propertyRef.value.units.length === 1 ? propertyRef.value.units[0].id : undefined,
        },
      };
    }
  },
};
</script>

<style lang="scss">
.page-book-a-service {
  h1 {
    font-size: 1.75rem;
    font-weight: normal;
    color: gray-color(darker);
  }

  > div > p {
    font-size: 1.25rem;
    color: gray-color(darker);
  }

  .select-unit-for-showing-block {
    margin-left: 2rem;
    .el-input__inner {
      min-width: 9rem;
      border-radius: $app-border-radius;
    }

    .el-button {
      border-radius: 0 5px 5px 0;
    }
  }
  .close-services {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>
